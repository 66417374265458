<template>
  <CCard>
    <!-- Header  start-->
    <CCardHeader>
      <h4 class="subhead-modal">History</h4>
    </CCardHeader>
    <!-- Header  end-->

    <!-- Body  start-->
    <CCardBody>
      <CRow class="mb-2">
        <CCol sm="10">
          <CLink
            class="card-header-action btn-minimize"
            @click="formCollapsed = !formCollapsed"
          >
            <CIcon
              size="lg"
              name="cil-chevron-double-down"
              v-if="formCollapsed"
            />
            <CIcon size="lg" name="cil-chevron-double-up" v-else />
          </CLink>
          <label><b>Search box</b></label>
        </CCol>
      </CRow>

      <CCollapse :show="formCollapsed">
        <CRow>
          <CCol>
            <CCard class="card border-secondary">
              <CCardHeader>
                <label>Detail</label>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <CSelect
                      label="Type"
                      :options="typeSelect"
                      placeholder="Please select"
                      v-model="searchObj.Type"
                      :value.sync="searchObj.Type"
                    />
                  </CCol>
                  <CCol>
                    <CInput label="Insight No." v-model="searchObj.InsightNo" />
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <!-- <CInput label="Create Date" type="date" /> -->
                    <label class="">Create Date</label>
                    <DatePicker
                      class="custom-input-date"
                      :first-day-of-week="2"
                      :masks="{ input: ['YYYY/MM/DD'], data: ['YYYY/MM/DD'] }"
                      v-model="searchObj.CreatedDate"
                      :value.sync="searchObj.CreatedDate"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="bg-white border px-2 py-1 rounded"
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="YYYY/MM/DD"
                          readonly
                        />
                      </template>
                    </DatePicker>
                  </CCol>
                  <CCol>
                    <CInput label="Idea No." v-model="searchObj.IdeaNo" />
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <CSelect
                      label="Status"
                      :options="statusSelect"
                      placeholder="Please select"
                      v-model="searchObj.Status"
                      :value.sync="searchObj.Status"
                    />
                  </CCol>
                  <CCol>
                    <CInput label="Concept No." v-model="searchObj.ConceptNo" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol md="4">
            <CCard class="card border-secondary">
              <CCardHeader>
                <label>Customer</label>
              </CCardHeader>
              <CCardBody>
                <CSelect
                  label="Industry"
                  :options="industrySelect"
                  v-model="searchObj.Industry"
                  :value.sync="searchObj.Industry"
                />
                <CSelect
                  label="Segment"
                  :options="segmentSelect"
                  v-model="searchObj.Segment"
                  :value.sync="searchObj.Segment"
                  @change="segmentOnChange($event)"
                />
                <CSelect
                  label="Sub Segment"
                  :options="subSegmentFilter"
                  v-model="searchObj.SubSegment"
                  :value.sync="searchObj.SubSegment"
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCollapse>

      <CRow>
        <CCol></CCol>
        <CCol class="text-center">
          <CInput label="Keyword" v-model="searchObj.Detail" horizontal>
            <template #prepend-content>
              <CIcon name="cil-magnifying-glass" />
            </template>
          </CInput>
        </CCol>
        <CCol></CCol>
      </CRow>

      <CRow>
        <CCol class="text-center">
          <CButton color="dark" class="mr-1" @click="ClearSearch()">Clear</CButton>
          <CButton color="primary" @click="SearchHistory()">Search</CButton>
        </CCol>
      </CRow>

      <CDataTable
        :items="historyData"
        :fields="fields"
        items-per-page-select
        :items-per-page="10"
        hover
        sorter
        pagination
        :loading="isLoading"
      >
        <template #CreatedNo="{item}">
          <td>
            <CLink class="text-primary" v-on:click="onClickDetail(item)">{{
              item.CreatedNo
            }}</CLink>
          </td>
        </template>
        <template #Action="{item}">
          <td>
            <CButton color="info" @click="GotoDetail(item)">Detail</CButton>
          </td>
        </template>
      </CDataTable>

      <InsightModal
        :showModal.sync="InsightModal"
        :item.sync="InsightObj"
        @InsightModal-updated="InsightModal = $event"
        :viewmode="viewmode"
      />

      <IdeaModal
        :showModal.sync="IdeaModal"
        @IdeaModal-updated="IdeaModal = $event"
        :viewmode="viewmode"
        :insightDataSelected="InsightSelected"
        @insightListSelected-updated="InsightSelected = $event"
        :item.sync="ideaObject"
        :onlyView="true"
      />

      <ConceptModal
        :showModal.sync="ConceptModal"
        @ConceptModal-updated="ConceptModal = $event"
        :item.sync="conceptObj"
        :viewmode="viewmode"
      />

    </CCardBody>
    <!-- Body  end-->
  </CCard>
</template>

<script>
import InsightModal from "../insight/InsightModal";
import IdeaModal from "../idea/IdeaModal";
import ConceptModal from "../concept/ConceptModal";
import { DatePicker } from "v-calendar";
import axios from "axios";
import store from "../../store";
import moment from "moment";

const fields = [
  { key: "Type", label: "Type" },
  { key: "CreatedNo", label: "Created No." },
  { key: "IndustryDesc", label: "Industry" },
  { key: "SegmentDesc", label: "Segment" },
  { key: "Detail", label: "Detail" },
  { key: "ShowStatus", label: "Current Status"},
  { key: "StringCreatedDate", label: "Created Date" },
  { key: "Action", label: "Action" }
];

const typeSelect = [
  { value: "", label: "--Select--" },
  { value: "1", label: "Insight" },
  { value: "2", label: "Idea" },
  { value: "3", label: "Concept" }
];

const statusSelect = [
  { value: "", label: "--Select--" },
  // { value: "1", label: "Draft" },
  { value: "1", label: "Waiting for manager approve" },
  { value: "2", label: "Waiting for review" },
  { value: "3", label: "Completed" }
];

export default {
  name: "MyTaskTable",
  components: { InsightModal, IdeaModal, ConceptModal, DatePicker },

  data() {
    return {
      viewmode: "view",
      fields,
      details: [],
      industrySelect: [{ value: null, label: "--Select--" }],
      segmentSelect: [{ value: null, label: "--Select--" }],
      subSegmentSelect: [{ value: null, label: "--Select--" }],
      subSegmentFilter: [{ value: null, label: "--Select--" }],
      typeSelect,
      statusSelect,
      formCollapsed: false,
      InsightModal: false,
      InsightObj: null,
      IdeaModal: false,
      ideaObject: null,
      InsightSelected: [],
      ConceptModal: false,
      conceptObj: null,
      createDate: "",
      historyData: [],
      // userProfile: JSON.parse(localStorage.getItem("userprofile")),
      userProfile: JSON.parse(localStorage.getItem("userprofile")),
      isLoading: true,
      searchObj: {
        Type: "",
        CreatedDate: "",
        Status: "",
        InsightNo: "",
        IdeaNo: "",
        ConceptNo: "",
        Industry: "",
        Segment: "",
        SubSegment: "",
        Detail: ""
      },
      createdNo:""
    };
  },
  methods: {
    onClickDetail(item) {
      switch (item.Type) {
        case "Insight":
          this.InsightObj = { InsightNo : item.CreatedNo };
          this.InsightModal = true;
          break;
        case "Idea":
          this.ideaObject = { IdeaNo : item.CreatedNo };
          this.IdeaModal = true;
          break;
        case "Concept":
          this.conceptObj = { ConceptNo : item.CreatedNo };
          this.ConceptModal = true;
          break;
      }
    },
    GetHistory() {
      if (this.userProfile.Role === "Admin") {
        axios
          .get(store.getters.URL + "/api/History/gethistorybyadmin", {
            params: { BU: this.userProfile.BU }
          })
          .then(res => {
            if (res.data.StatusCode === 200) {
              this.historyData = res.data.Data;              
            } else {
              this.$_toast_Noti("error", "error", res.data.Message);
            }
            this.isLoading = false;
          })
          .catch(err => {
            this.$_toast_Noti("error", "error", err.Message);
            this.isLoading = false;
          });
      } else {
        axios
          .get(store.getters.URL + "/api/History/gethistorybycreator", {
            params: { BU: this.userProfile.BU, username: this.userProfile.Username }
          })
          .then(res => {
            if (res.data.StatusCode === 200) {
              this.historyData = res.data.Data;
            } else {
              this.$_toast_Noti("error", "error", res.data.Message);
            }
            this.isLoading = false;
          })
          .catch(err => {
            this.$_toast_Noti("error", "error", err.Message);
            this.isLoading = false;
          });
      }
    },
    GotoDetail(item) {
      this.$router.push({ path: "/history/HistoryDetail",query:{CreatedNo:item.CreatedNo} });
      //link ไปหน้า detail และส่ง documentId ไป//
    },
    GetIndustry() {
      axios.get(store.getters.URL + "/api/MasterIndustry/getall").then(res => {
        let Options = [{ value: null, label: "-- Select --" }];
        res.data.forEach(function(data) {
          Options.push({
            value: data.IndustryID,
            label: data.IndustryDesc
          });
        });
        this.industrySelect = Options;
      });
    },
    GetSegment() {
      axios.get(store.getters.URL + "/api/MasterSegment/getall").then(res => {
        let Options = [{ value: null, label: "-- Select --" }];
        res.data.forEach(function(data) {
          Options.push({
            value: data.SegmentID,
            label: data.SegmentDesc
          });
        });
        this.segmentSelect = Options;
      });
    },
    GetSubSegment() {
      axios
        .get(store.getters.URL + "/api/MasterSubSegment/getall")
        .then(res => {
          this.subSegmentSelect = res.data;
        });
    },
    segmentOnChange(event) {
      var MySubSegmentList = this.subSegmentSelect.filter(function(obj) {
        return obj.SegmentID == event.target.value;
      });

      let Options = [{ value: null, label: "--Select--" }];
      MySubSegmentList.forEach(function(data) {
        Options.push({
          value: data.SubSegmentID,
          label: data.SubSegmentDesc
        });
      });
      this.subSegmentFilter = Options;
      this.searchObj.SubSegment = null;
    },
    SearchHistory() {
      this.isLoading = true;
      let item = {
        Type: this.searchObj.Type,
        CreatedDate: moment(this.searchObj.CreatedDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
        Status:this.searchObj.Status,
        InsightNo:this.searchObj.InsightNo,
        IdeaNo:this.searchObj.IdeaNo,
        ConceptNo:this.searchObj.ConceptNo,
        Industry: this.searchObj.Industry,
        Segment: this.searchObj.Segment,
        SubSegment: this.searchObj.SubSegment,
        Detail:this.searchObj.Detail,
        BU:this.userProfile.BU,
        Username:this.userProfile.Username
      };

      //console.log("item",item);
      
      if(this.userProfile.Role === "Admin"){
        axios.get(store.getters.URL + '/api/History/searchhistorybyadmin',{params:item}).then(res => {
          if (res.data.StatusCode === 200) {
              this.historyData = res.data.Data;
            } else {
              this.$_toast_Noti("error", "error", res.data.Message);              
            }
            this.isLoading = false;
        }).catch(err => {
          this.$_toast_Noti("error", "error", err.Message);
          console.error(err.Message)
          this.isLoading = false;
        })
      }else{
        axios.get(store.getters.URL + '/api/History/searchhistorybycreator',{params:item}).then(res => {
          if (res.data.StatusCode === 200) {
              this.historyData = res.data.Data;              
          } else {
            this.$_toast_Noti("error", "error", res.data.Message);              
          }
          this.isLoading = false;
        }).catch(err => {
          this.$_toast_Noti("error", "error", err.Message);
          console.error(err.Message)
          this.isLoading = false;
        })
      }
    },
    ClearSearch(){
      this.searchObj.Type = ''
      this.searchObj.CreatedDate = ''
      this.searchObj.Status = ''
      this.searchObj.InsightNo = '' 
      this.searchObj.IdeaNo = '' 
      this.searchObj.ConceptNo = '' 
      this.searchObj.Industry = '' 
      this.searchObj.Segment = '' 
      this.searchObj.SubSegment = ''
      this.searchObj.Detail = '' 
      //clear ข้อมูลในช่อง search//
    }
  },
  mounted() {
    this.GetHistory();
    this.GetSegment();
    this.GetSubSegment();
    this.GetIndustry();
  }
};
</script>
